import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [

  {
    path: '',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule)
  },
  {
    path: 'push',
    loadChildren: () => import('./pages/push/push.module').then(m => m.PushPageModule)
  },
  {
    path: 'stripe',
    loadChildren: () => import('./pages/stripe/stripe.module').then(m => m.StripePageModule)
  },
  {
    path: 'categorias',
    loadChildren: () => import('./pages/categorias/categorias.module').then(m => m.CategoriasPageModule)
  },
  {
    path: 'tipos-menu',
    loadChildren: () => import('./pages/tipos-menu/tipos-menu.module').then(m => m.TiposMenuPageModule)
  },
  {
    path: 'deals',
    loadChildren: () => import('./pages/deals/deals.module').then(m => m.DealsPageModule)
  },
  {
    path: 'administracion',
    loadChildren: () => import('./pages/administracion/administracion.module').then(m => m.AdministracionPageModule)
  },
  {
    path: 'cupon',
    loadChildren: () => import('./pages/cupon/cupon.module').then(m => m.CuponPageModule)
  },
  {
    path: 'productos-grocery',
    loadChildren: () => import('./pages/productos-grocery/productos-grocery.module').then(m => m.ProductosGroceryPageModule)
  },
  {
    path: 'sides-order',
    loadChildren: () => import('./pages/sides-order/sides-order.module').then(m => m.SidesOrderPageModule)
  },
  {
    path: 'banner',
    loadChildren: () => import('./pages/banner/banner.module').then(m => m.BannerPageModule)
  },
  {
    path: 'content',
    loadChildren: () => import('./pages/content/content.module').then(m => m.ContentPageModule)
  },
  {
    path: 'sizes',
    loadChildren: () => import('./pages/sizes/sizes.module').then(m => m.SizesPageModule)
  },
  {
    path: 'modifiers',
    loadChildren: () => import('./pages/modifiers/modifiers.module').then(m => m.ModifiersPageModule)
  },
  {
    path: 'reset',
    loadChildren: () => import('./pages/reset/reset.module').then(m => m.ResetPageModule)
  },
  {
    path: 'taxes',
    loadChildren: () => import('./pages/taxes/taxes.module').then(m => m.TaxesPageModule)
  },  {
    path: 'config',
    loadChildren: () => import('./pages/config/config.module').then( m => m.ConfigPageModule)
  },
  {
    path: 'tables',
    loadChildren: () => import('./tables/tables.module').then( m => m.TablesPageModule)
  },






];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
