import { RolEntity } from "./rol.entity";

export class UserStoreEntity {
  id: number;
  nombre: string;
  apellido: string;
  alias: string;
  password: string;
  is_active: boolean;
  rolId: number;
  created_at: string;
  email: string;
  store: string;
  store_id: number;
  storeId: number;
  formulario_pedido: boolean;
  estado_tienda: string;
  wp_url: string;
  push_order: string;
  rol: RolEntity;
  delivery: boolean;
  pais: string;

}
