import { NavigationExtras, Router } from '@angular/router';
import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { OneSignal, OSDisplayType } from '@ionic-native/onesignal/ngx';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import { UserStoreEntity } from './models/user_store.entity';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Inbox', url: '/folder/Inbox', icon: 'mail' },
    { title: 'Outbox', url: '/folder/Outbox', icon: 'paper-plane' },
    { title: 'Favorites', url: '/folder/Favorites', icon: 'heart' },
    { title: 'Archived', url: '/folder/Archived', icon: 'archive' },
    { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
    { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  userstore: UserStoreEntity = new UserStoreEntity();
  constructor(private platform: Platform,
    private oneSignal: OneSignal,
    private insomnia: Insomnia,
    private router: Router,
  ) {
    this.initOneSignal();
    this.notSleep();
  }


  initOneSignal() {
    this.oneSignal.setLogLevel({ logLevel: 6, visualLevel: 0 });
    this.oneSignal.startInit('48f03ea0-2c44-413d-9d13-959bb8aab5be', 'N2M2MTEwMzAtMGEyMC00NmM0LTk2OTUtMGNkNmY0NDA2NjZk');



    this.oneSignal.getIds().then(data => {
      console.log(data.userId)
      localStorage.tokenDevice = data.userId;
    }, err => {
      console.error(err);
    });

    this.oneSignal.handleNotificationReceived().subscribe(() => {

      this.showMessage();


    });

    this.oneSignal.endInit();
  }


  sound() {
    //let link='https://res.cloudinary.com/doxdyuaso/video/upload/v1603730584/vdgi2k5j6drnad9pdnmg.wav';
    let link = 'https://res.cloudinary.com/doxdyuaso/video/upload/v1639711041/qd1zajdogbzzqwlxot5r.mp3';
    var audio = new Audio(link);
    audio.play();


  }
  showMessage() {
    this.sound();
    let navigationExtras: NavigationExtras = {
      state: {
        segment: 'web',
      },
    };
    this.router.navigate(['/dashboard/pedidos'], navigationExtras);
    //alert("tienes un pedido nuevo");
  }

  notSleep() {
    console.log("plugin activado...")
    this.insomnia.keepAwake()
      .then(
        () => console.log('success'),
        () => console.log('error')
      );
  }

  _session() {
    this.userstore = JSON.parse(localStorage.getItem('userstore'));
    if (this.userstore.store !== '') {
      this.router.navigateByUrl('dashboard/pedidos');
    }
  }


}
