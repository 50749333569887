import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxIonicImageViewerModule } from 'ngx-ionic-image-viewer';
import { SwiperModule } from 'swiper/angular';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { ImageCropperModule } from 'ngx-image-cropper';
import { BluetoothSerial } from '@awesome-cordova-plugins/bluetooth-serial/ngx';
import { Printer } from '@awesome-cordova-plugins/printer/ngx';
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';

// import ngx-translate and the http loader


import {HttpClient, HttpClientModule} from '@angular/common/http';

//const config: SocketIoConfig = { url: 'http://localhost:5000', options: {} };

const config: SocketIoConfig = { url: 'http://grocery.appstgs.com:5000', options: {} };



@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(),
    HttpClientModule,
     NgxIonicImageViewerModule,
    AppRoutingModule,
    SwiperModule,
    SocketIoModule.forRoot(config),
    BrowserAnimationsModule
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, NativeGeocoder,OneSignal, Insomnia, Printer,
    BluetoothSerial,],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }


